import React from 'react'
import agilysis from '../images/integrations/agilysis.svg'
import clock from '../images/integrations/clock.svg'
import maestro from '../images/integrations/maestro.svg'
import hop from '../images/integrations/hop.svg'
import raccoon from '../images/integrations/roomraccoon.svg'
import revinate from '../images/integrations/revinate.svg'

const trustedBy = [
  {
    id: 1,
    alt: 'Agilysys',
    src: agilysis,
  },
  {
    id: 2,
    alt: 'Hop',
    src: hop,
  },
  {
    id: 3,
    alt: 'Clock PMS+',
    src: clock,
  },
  {
    id: 5,
    alt: 'Maestro',
    src: maestro,
  },
  {
    id: 6,
    alt: 'RoomRaccoon',
    src: raccoon,
  },
  {
    id: 7,
    alt: 'Revinate',
    src: revinate,
  },
]

export const PartnerLogoComingSoon = () => (
  <div className="grid grid-cols-3 md:grid-cols-6 gap-6">
      {trustedBy.map(({ id, alt, src }, index) => (
        <div
          key={id}
          className="bg-white bg-opacity-10 rounded-md aspect-square flex items-center justify-center p-4"
          data-sal="slide-up"
          data-sal-duration="500"
          data-sal-delay={(index * 100) + 500}
          data-sal-easing="ease"
        >
          <img src={src} alt={alt}/>
        </div>
      ))}
  </div>
)
